/*
 * APNIC Utilities
 *
*/

// Background Colors

.bg-primary-100 {
    background-color: $primary-100 !important;
}

.bg-primary-200 {
    background-color: $primary-200 !important;
}

.bg-primary-300 {
    background-color: $primary-300 !important;
}

.bg-primary-400 {
    background-color: $primary-400 !important;
}

.bg-primary-500 {
    background-color: $primary-500 !important;
}

.bg-primary-600 {
    background-color: $primary-600 !important;
}

.bg-primary-700 {
    background-color: $primary-700 !important;
}

.bg-primary-800 {
    background-color: $primary-800 !important;
}



.bg-gray-50 {
    background-color: $gray-50 !important;
}

.bg-gray-100 {
    background-color: $gray-100 !important;
}

.bg-gray-200 {
    background-color: $gray-200 !important;
}

.bg-gray-300 {
    background-color: $gray-300 !important;
}

.bg-gray-400 {
    background-color: $gray-400 !important;
}

.bg-gray-500 {
    background-color: $gray-500 !important;
}

.bg-gray-600 {
    background-color: $gray-600 !important;
}

.bg-gray-700 {
    background-color: $gray-700 !important;
}

.bg-gray-800 {
    background-color: $gray-800 !important;
}

.bg-gray-900 {
    background-color: $gray-900 !important;
}

.bg-black {
    background-color: $black !important;
}

// Box-shadow

.shadow {
    box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.1) !important;
}

.shadow-none {
    box-shadow: none !important;
}
