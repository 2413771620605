/*
 * Component: APNIC Float labels
 *
 */


@import "../mixins/apnic_mixins";

.has-float-label {
  @include float-label-container;

  @include float-label;

  select {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
  }

  textarea {
    width: 100%;
  }

  input,
  select,
  textarea {

    @include float-label-input;
    margin-bottom: 2px;
    border: 0;

    border-bottom: 2px solid $primary;
    border-radius: 0;

    padding-top: 1rem;
    font-size: inherit;

    @include float-label-scaled {
      top: 1rem;
    }

    &:focus {
      outline: none;
    }
  }

  select {
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.5rem bottom 0.25rem;
    background-size: 8px 24px;
    padding-right: 1rem;
    height: auto !important;
  }
}
