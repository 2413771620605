/*
 * Component: Card
 *
 */

.card {
     margin-bottom: 24px;
     border: none;
     box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.1);

     .card-header {
         border-bottom: none;
         background-color: $gray-100;
         padding: 0.5rem 1.25rem;
         font-weight: normal;
     }

     .card-footer {
         border-top: none;
         background-color: $gray-100;
         padding: 0.5rem 1.25rem;
         font-weight: normal;
     }
}

//
// Header navs
//

.card-header-tabs {
    margin-bottom: -0.5rem;
}
