/*
* APNIC Web Styles
*
* This file imports all framework elements that will feed to all of APNIC's web
* identities.
*
*/

// APNIC Variables Overides
@import "variables";

// Bootstrap4 library
// @import "bootstrap/scss/functions";
// @import "bootstrap/scss/variables";
// @import "bootstrap/scss/mixins";
@import "bootstrap/scss/bootstrap";

// FontAwesome library
@import "font-awesome/main";

// APNIC Components Overides
@import "base/body";
@import "base/html";
@import "base/type";
@import "components/alert";
@import "components/apnic_float_labels";
@import "components/apnic_form";
@import "components/apnic_footer";
@import "components/apnic_header";
@import "components/apnic_nav";
@import "components/buttons";
@import "components/card";
@import "components/modal";
@import "components/nav";
@import "components/popover";
@import "components/shadowbox";

// APNIC Utilities
@import "utilities";
