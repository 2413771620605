/*
 * Component: Alert
 * Bootstrap Overwrites
 *
 */

.alert {
    border: none;

    a,
    .alert-link {
        text-decoration: underline;
        color: inherit !important;
    }
}

.alert-primary,
.alert-secondary,
.alert-success,
.alert-danger,
.alert-info,
.alert-dark {
    color: $white !important;
}

.alert-warning {
    color: $black !important;
}
