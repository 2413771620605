/*
 * APNIC mixins
 *
 */

//
// Mixins for float labels
//

@mixin float-label-container {
  display: block;
  position: relative;
}

@mixin float-label {

    label,
    span {
        position: absolute;
        top: -5px;
        left: 0;
        opacity: 1;
        cursor: text;
        font-size: 1rem * 0.75;
        color: $primary;
        transition: all 0.2s;
        @content;
    }
}

@mixin float-label-input {

  &::placeholder {
    opacity: 1;
    transition: all 0.2s;
  }

  &:placeholder-shown:not(:focus)::placeholder {
      opacity: 0;
  }
}

@mixin float-label-scaled {

  &:placeholder-shown:not(:focus) + * {
      font-size: 1rem;
      @content;
      color: $gray-600;
  }
}
