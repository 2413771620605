/*
 * Variables: Font
 *
 */

$primary-font-family:         "Whitney SSm A", "Whitney SSm B", "Arial", sans-serif;
$font-family-sans-serif:      $primary-font-family;

$headings-font-weight:        600;
$headings-margin-bottom:      1.5rem;
$headings-margin-top:         1.5rem;
