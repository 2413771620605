/*
 * Component: APNIC Primary Navigation
 *
 */

//
// APNIC Website Main Navigation
//

#apnic-navbar-content {

    &.navbar {
        position: inherit;
        padding: 0;
    }

    .container {
        display: none;
        overflow-y: auto;
        position: absolute;

        top: 0;

        z-index: $zindex-sticky;

        background-color: $white;

        padding: 0;
        padding-top: 43px;

        width: 70%;
        height: 100%;
    }

    .container.menu-slide-left {
        animation: menuSlideLeft 0.5s;
    }

    .container.menu-slide-right {
        animation: menuSlideRight 0.5s;
    }

    @keyframes menuSlideLeft {

        from { left: -70%; }

        to { left: 0; }
    }

    @keyframes menuSlideRight {

        from { left: 0; }

        to { left: -70%; }
    }

    .nav-item {

        width: 100%;

        a.nav-link {
            background-color: transparent;
            padding: 0.7rem 1.3rem;
            font-size: 0.9rem;
            color: $gray-700;

            &.active {
            outline: none;
                background-color: $gray-100;
            }

            &:hover {
                background-color: $gray-100;
            }

            &:focus {
                outline: none;
                background-color: $gray-100;
            }

            &.nav-link-login {
                background-color: $primary-700;
                text-transform: uppercase;
                color: $white;

                &:hover {
                    background-color: $primary-800;
                }
            }
        }

        &.user-profile {
            background-color: $primary-700;
            padding: 0.7rem 1.3rem;

            .user-greetings {
                font-size: $h6-font-size;
                color: $white;
            }

            .user-name {
                font-size: $h3-font-size;
                color: $white;
            }
        }

        &.dropdown {

        .dropdown-menu {
            position: relative;
            top: 0;
            float: inherit;
            border: none;
            padding: 0;
            width: 100%;

                a.dropdown-item {
                    padding: 0.7rem 1.3rem;
                    text-indent: 1rem;
                    font-size: 0.9rem;
                    color: $gray-700;

                    &:hover {
                        background-color: transparent;
                        color: $primary;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: map-get($grid-breakpoints, lg)) {

        display: block;
        padding: 0;

        .container {
            display: block;
            overflow-y: unset;
            position: relative;

            top: 0;

            padding: 0;

            width: 100%;
            height: auto;

            &.menu-slide-left,
            &.menu-slide-right {
                animation: none;
            }

            & ul::after {
                display: block;
                position: relative;
                //border-bottom: solid 3px $primary;
                background-color: $primary;
                background-image: url("../images/colour-flick-bar-blue.png");
                background-repeat: no-repeat;
                background-position: right;
                width: 100%;
                height: 3px;
                content: "";
            }
        }

        .nav-item {
            position: relative;
            width: auto;

            a.nav-link.active {
                background-color: $primary;
                color: $white;
            }

            a.nav-link {
                padding: 0.45rem 1rem;
                font-size: 0.9rem;
                color: $primary;

                &::after {
                    border-color: $gray-300 transparent transparent transparent;
                }

                &:hover,
                &:focus,
                &:active {
                    background-color: $primary;
                    color: $white;

                    &::after {
                        border-color: $white transparent transparent transparent;
                    }
                }
            }

            &.dropdown {

                &:hover,
                &:focus {

                    a.nav-link {
                        background-color: $primary;
                        color: $white;
                    }
                }

                .dropdown-menu {
                    position: absolute;
                    top: auto;
                    margin-top: 0;
                    border: 0;
                    background-color: rgba($primary, 0.9);
                    padding: 0;
                    width: 220px;

                    a.dropdown-item {
                        padding: 0.7rem 1rem;
                        text-indent: 0;
                        font-size: 0.9rem;
                        color: $white;

                        &:hover,
                        &:focus {
                            background-color: $primary;
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}


//
// APNIC Utility Bar (aka Black Bar)
//

.navbar-apnic {
    z-index: $zindex-fixed;
    background-color: $primary;
    padding: 0 !important;
    color: $white;

    .navbar-toggler {
        padding: 0.65rem;
        color: $white;
    }

    .navbar-brand-apnic {
        margin: auto;
    }

    .navbar-brand-apnic {

        padding: 0;
        padding-bottom: 2px;

        img {
            width: 59px;
            height: 20px;
        }
    }

    .navbar-brand-apnic.apnic-main {
        display: block;
    }

    .navbar-search {

        padding: 2px 0;

        .form-control {
            border: solid 1px $white;
            width: 180px;
            height: auto;
            background-clip: unset;
            font-size: ($font-size-base * 0.75);
        }

        .btn {
            background-color: $white;
            font-size: ($font-size-base * 0.75);
        }
    }

    #apnic-nav-search-content ul.navbar-nav {
      padding: 2em 6em;
    }

    .navbar-collapse {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 34px;
        width: 100%;

        .navbar-search {
            justify-content: center;
            margin-top: 0;
            margin-bottom: -32px;

            input {
                width: 100%;
            }

            button {
                position: relative;
                top: -32px;
                margin-left: auto;
            }
        }

        ul.navbar-nav {

            .nav-item {
                margin-bottom: 0.5em;
            }
        }

        ul.navbar-nav li:nth-child(n+3) {
            display: none;
        }
    }

    .navbar-collapse.fade-in {
        animation: fadeIn 1s;
    }

    .navbar-collapse.fade-out {
        animation: fadeOut 0.5s;
    }

    @keyframes fadeIn {

        from { opacity: 0; }

        to { opacity: 1; }
    }

    @keyframes fadeOut {

        from { opacity: 1; }

        to { opacity: 0; }
    }
}

@media only screen and (min-width: map-get($grid-breakpoints, lg)) {

    .navbar-apnic {
        background-color: $gray-850;

        .navbar-brand-apnic.apnic-main {
            display: none;
        }

        .navbar-collapse {
            position: relative;
            margin-top: 0;

            ul.navbar-nav li:nth-child(n+3) {
                display: block;
            }
        }

        #apnic-nav-search-content ul.navbar-nav {
            padding: 0;
        }


        .nav-item {
            margin-bottom: 0 !important;

            a.nav-link {
                cursor: pointer;
                padding: 9px 12px;
                text-transform: uppercase;
                font-size: ($font-size-base * 0.75);
                color: $white;

                &#directionsDropdown {
                    background: url("../images/icon-grid.png") no-repeat center;
                    width: 36px;
                    height: 36px;

                    &:hover {
                        background-color: $gray-800;
                    }
                }

                &:hover {
                    background-color: $gray-800;
                }
            }

            a.nav-link.btn {
                border: none;
            }

            .dropdown-menu {
                padding: 0;

                &.navbar-dropdown-menu {
                    margin: 0;
                    border: none;
                    background-color: $gray-100;
                    font-size: 0.9rem;
                    box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.1);

                    a {
                        padding: 8px 20px;
                        text-transform: uppercase;
                        font-size: 0.75rem;

                        &:hover,
                        &:active,
                        &:focus {
                            background-color: $gray-200;
                            color: inherit;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: map-get($grid-breakpoints, sm)) {

    #apnic-nav-search-content ul.navbar-nav {
        padding: 1rem !important;
    }
}

//
// APNIC Application Utility Black Bar
//

body.app-body {

    @media only screen and (max-width: map-get($grid-breakpoints, lg)) {

        .navbar-apnic {
            background-color: $gray-850;

            .navbar-brand-apnic {
                margin: auto 1rem;
            }

            .navbar-collapse {
                display: flex !important;
                flex-basis: auto;
                position: inherit;
                top: 0;
                left: 0;
                margin-top: 0;
                width: auto;

                input {
                    width: 100%;
                }

                button {
                    position: relative;
                    top: -32px;
                    margin-left: auto;
                }


                ul.navbar-nav {
                    padding: 0;
                    flex-direction: row;

                    li.nav-item {
                        margin-bottom: 0;

                        &:nth-child(n+3) {
                            display: block;
                        }

                        a.nav-link {
                            cursor: pointer;
                            padding: 9px 12px;
                            text-transform: uppercase;
                            font-size: 0.75rem;
                            color: #FFFFFF;

                            &.btn {
                                border: none;
                            }

                            &#directionsDropdown {
                                background: url("../images/icon-grid.png") no-repeat center;
                                width: 36px;
                                height: 36px;
                            }
                        }

                        .dropdown-menu.navbar-dropdown-menu {
                            position: absolute;
                            margin: 0;
                            padding: 0;
                            border: none;
                            background-color: $gray-100;
                            font-size: 0.9rem;
                            box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.1);

                            a {
                                padding: 8px 20px;
                                text-transform: uppercase;
                                font-size: 0.75rem;

                                &:hover,
                                &:active,
                                &:focus {
                                    background-color: $gray-200;
                                    color: inherit;
                                }
                            }
                        }

                        .dropdown-menu-right {
                            right: 0;
                            left: auto;
                        }
                    }
                }
            }
        }
    }
}
