/*
 * Base: type
 *
 */

//
// Headings
//

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: $headings-margin-top;
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;

  &:first-child {
      margin-top: 0;
  }
}

h1,
.h1 {
    font-size: $h1-font-size;
    color: $primary-600;
}

h2,
.h2 {
    font-size: $h2-font-size;
    color: $primary-700;
}

h3,
.h3 {
    font-size: $h3-font-size;
    color: $primary-800;
}

h4,
.h4 {
    font-size: $h4-font-size;
}

h5,
.h5 {
    font-size: $h5-font-size;
}

h6,
.h6 {
    font-size: $h6-font-size;
}

.lead {
  font-size: $lead-font-size;
  font-weight: $lead-font-weight;
}

.app-section-title {
    margin-bottom: 0.5rem;
    font-size: ($font-size-base * 1.5);
    font-weight: $font-weight-normal;
    color: inherit;
}

//
// Blockquotes
//

blockquote,
.blockquote {
  margin-bottom: $spacer;
  border-left: $border-width-l solid $gray-light;
  padding-left: $spacer;
  font-size: $blockquote-font-size;

  p {
      margin: 0;
      font-size: 1rem;
  }

  .blockquote-footer,
  cite {
    display: block;
    font-size: 80%; // back to default font-size
    color: $blockquote-small-color;

    &::before {
      content: "\2014 \00A0"; // em dash, nbsp
    }
  }
}

//
// Horizontal rule
//

hr {
    width: 100%;
}
