/*
 * Component: Modal
 *
 */

 // Modal header

.modal-header {

    .close {
        margin: -13px;
    }
}
