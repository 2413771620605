/*
 * Variables: Navbar
 *
 */

 // Navbar

 $navbar-dark-toggler-border-color:  transparent;

 $navbar-light-color:                $gray-600;
 $navbar-light-hover-color:          $gray-800;
 $navbar-light-active-color:         $black;
 $navbar-light-disabled-color:       $gray-400;
 $navbar-light-toggler-border-color: transparent;
