/*
 * Variables to overwrite bootstrap defaults
 *
*/

// Put the variables that APNIC will overwrite below.
// REMEMBER to remove DEFAULT!!!!

@import "variables/alert";
@import "variables/color";
@import "variables/border";
@import "variables/breadcrumbs";
@import "variables/code";
@import "variables/font";
@import "variables/forms";
@import "variables/link";
@import "variables/navbar";
@import "variables/zindex";

// Enabled features

$enable-flex: true;
$enable-rounded: false;
// $enable-shadows: true;
$enable-gradients: false;
$enable-transitions: true;
