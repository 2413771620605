/*
 * Base: body
 *
 * Body for Website
 * Body for Application
 */

 //
 // Body for Websites
 //

 body {
     margin-bottom: 123px;

     @media only screen and (max-width: map-get($grid-breakpoints, lg)) {
         margin-bottom: 266px;
     }

     @media only screen and (max-width: 338px) {
         margin-bottom: 317px;
     }
 }


//
// Body for Applications
//

 body.app-body {
     margin-bottom: 53px;

     @media screen and (max-width: 1200px) {
         margin-bottom: 111px;
     }

     @media only screen and (max-width: map-get($grid-breakpoints, lg)) {
         margin-bottom: 111px;
     }

     @media only screen and (max-width: 338px) {
         margin-bottom: 11px;
     }
 }
