/*
 * Variables: Border
 *
 */

$border-width:       1px;
$border-width-s:     1.374px;
$border-width-m:     3.356px;
$border-width-l:     6.554px;

// Bootstrap Overrides of border radius
$border-radius:      0;
$border-radius-lg:   0;
$border-radius-sm:   0;
