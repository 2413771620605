/*
 * Component: Footer
 *
 * Footer for Website
 * Footer for Application
 *
 */


//
// Footer for Website
//

footer.relative {
    position: relative !important;
}

footer.footer {
    position: absolute;
    bottom: 0;
    margin: 0;
    background-color: $gray-850;
    padding: 1rem;
    width: 100%;
    font-size: $font-size-base * 0.9;
    color: $white;

    .size-1-of-4,
    .size-3-of-4 {
        vertical-align: middle;

        @media screen and (max-width: map-get($grid-breakpoints, lg)) {
            width: 100% !important;
        }
    }

    .footer-logo {
        margin: 0;
        background: url("../images/apnic-reverse.svg") no-repeat;
        background-size: auto 28px;
        height: 25px;

        @media screen and (max-width: map-get($grid-breakpoints, lg)) {
            margin: 0 0 16px 0;
            margin-right: auto;
            margin-left: auto;
            background-size: 100px 28px;
            max-width: 100px;
        }
    }

    a.fa {
        text-decoration: none;
        color: $white;

        &:hover {
            color: $primary-200;
        }
    }

    .footer-social {
        text-align: right;

        @media screen and (max-width: map-get($grid-breakpoints, lg)) {
            width: 100%;
            text-align: center;
            font-size: 14px;
        }

        ul {
            display: inline-block;
            margin: 0 0 0 16px;
            padding: 0;
            list-style-type: none;
            vertical-align: middle;

            @media screen and (max-width: map-get($grid-breakpoints, lg)) {
                margin: 10px 0 0 0;
                width: 100%;
                font-size: 20px;
            }

            li {
                display: inline-block;

                a {
                    display: block;
                    width: 30px;
                    color: $white;
                }
            }
        }
    }

    .footer-aside {
        clear: both;
        margin: 16px 0 8px;
        border-top: 1px solid $gray-100;
        padding: 16px 0;

        .footer-aside-copyright {
            float: left;
            margin: 0 0 16px 0;
            text-align: left;

            @media screen and (max-width: map-get($grid-breakpoints, lg)) {
                width: 100%;
                text-align: center;
            }

            .footer-aside-copyright-abn {
                display: inline-block;
                margin-left: 16px;
            }
        }

        .footer-aside-links {
            float: right;
            margin: 0;
            padding: 0;
            max-width: none;
            list-style-type: none;

            @media screen and (max-width: map-get($grid-breakpoints, lg)) {
                float: none;
                margin: 0 auto;
                max-width: 350px;
                text-align: center;
            }

            li {
                display: inline-block;
                margin: 0;
                padding: 0 0 0 15px;

                a {
                    color: $white;
                }
            }
        }
    }
}

//
// Footer for Application
//

 footer.app-footer {
    position: absolute;
    bottom: 0;
    margin: 0;
    background-color: $gray-850;
    padding: 1rem;
    width: 100%;
    font-size: $font-size-base * 0.9;
    color: $white;

    @include media-breakpoint-down(sm) {
        bottom: inherit;
    }

    ul {
        display: inline-block;
        margin: 0;
        padding: 0;
        list-style-type: none;
        vertical-align: middle;

        li a {
            margin: 0 0.5rem;
            text-decoration: none;
            color: $white;
        }

    }

    @media screen and (max-width: 1200px) {

        div {
            flex-direction: column;

            ul {
                justify-content: center !important;
                margin-top: 0.5rem;
            }
        }
    }
}
