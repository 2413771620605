/*
 * Variables: Colors
 *
 */

$white:    #FFFFFF;
$black:    #000000;
$gray-50: lighten($black, 95%);
$gray-100: lighten($black, 90%);
$gray-200: lighten($black, 80%);
$gray-300: lighten($black, 70%);
$gray-400: lighten($black, 60%);
$gray-500: lighten($black, 50%);
$gray-600: lighten($black, 40%);
$gray-700: lighten($black, 30%);
$gray-750: lighten($black, 25%);
$gray-800: lighten($black, 20%);
$gray-850: lighten($black, 15%);
$gray-900: lighten($black, 10%);
$gray-950: lighten($black, 5%);

$apnic-blue:    #007BBD;
$green:         #208836;
$cyan:          #118193;
$purple:        #8A4E94;
$red:           #B00020;

$blue:          $apnic-blue;
$primary:       $apnic-blue;
$secondary:     $gray-600;
$light:         $gray-100;
$dark:          $gray-800;
$success:       $green;
$info:          $purple;
$danger:        $red;

$gray:          $gray-500;
$gray-light:    $light;
$gray-dark:     $dark;

$primary-100:   lighten($primary, 55%);
$primary-200:   lighten($primary, 45%);
$primary-300:   lighten($primary, 30%);
$primary-400:   lighten($primary, 15%);
$primary-500:   $primary;
$primary-600:   darken($primary, 5%);
$primary-700:   darken($primary, 15%);
$primary-800:   darken($primary, 20%);

$theme-colors: (
  primary:      $apnic-blue,
  success:      $success,
  info:         $info,
  danger:       $danger,
);
