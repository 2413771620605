/*
 * Component: APNIC Form
 *
 */

.apnic-form {

     .form-control {
         height: auto;

         &:focus {
             border-color: $primary;
             box-shadow: none;
         }
     }

     .form-row {
         margin: 0;

         .col,
         [class*="col-"] {
             padding-right: 10px;
             padding-left: 0;
         }
     }

     .form-check {
         margin-bottom: 1rem;
         padding-left: 0;

         .form-check-input {
             margin-left: 0;
         }

         .form-check-label {
             margin-left: 1.5rem;
         }
     }

 }
