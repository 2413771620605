/*
 * Variables: Breadcrumbs
 *
 */

 $breadcrumb-padding-y:              0.75rem;
 $breadcrumb-padding-x:              0;

 // The separator between breadcrumbs (by default a ">" element)
 $breadcrumb-item-padding:           0.5rem;

 $breadcrumb-margin-bottom:          1rem;

 $breadcrumb-bg:                     $white;
 $breadcrumb-divider-color:          $gray-600;
 $breadcrumb-active-color:           $gray-600;
 $breadcrumb-divider:                quote(">");
