/*
 * Component: Popover
 *
 */

.popover {
    font-family: inherit;
}

.popover-header {
    margin: 0;
}
