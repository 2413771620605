/*
 * Component: Buttons
 *
 */

 button,
 .btn {
    cursor: pointer;
 }

 button.navbar-toggler:focus {
    outline: none;
}

// Inputs act like a button

input[type=submit],
input[type=button] {
    display: inline-block;
    border: 1px solid transparent;
    background-color: $primary;
    cursor: pointer;
    vertical-align: middle;
    text-align: center;
    font-weight: $btn-font-weight;
    white-space: nowrap;
    color: $white;
    user-select: none;
    @include button-size($input-btn-padding-y, $input-btn-padding-x, $font-size-base, $input-btn-line-height, $btn-border-radius);
    @include transition($btn-transition);
}
