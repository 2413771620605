/*
 * Component: Shadowbox
 *
 * This is used for when an overlayed component is displayed and you want to
 * remove focus on the underlaying elements.
 *
 */

.shadowbox {
    display: none;
    position: absolute;
    top: 0;
    opacity: 0.5;

    z-index: $zindex-dropdown;

    background-color: $black;

    width: 100%;
    height: 100%;
}

.shadowbox.fade-in {
    animation: shadowFadeIn 1s;
}

.shadowbox.fade-out {
    animation: shadowFadeOut 0.5s;
}

@keyframes shadowFadeIn {

    from { opacity: 0; }

    to { opacity: 0.5; }
}

@keyframes shadowFadeOut {

    from { opacity: 0.5; }

    to { opacity: 0; }
}

@media only screen and (min-width: map-get($grid-breakpoints, lg)) {

    .shadowbox.d-block {
        display: none !important;
    }
}
