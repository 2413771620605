/*
 * Component: Nav
 *
 */

//
// Underline type of navs
//


ul.nav.nav-underline {
    border-bottom: 1px solid $gray-200;

    .nav-item {
        padding: 0 1rem;

        .nav-link {
            padding: 0.3rem;

            &.active {
                border-bottom: 2px solid $primary-600;
            }
        }
    }
}
