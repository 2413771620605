/*
 * Component: APNIC Header
 *
 */

header.apnic-header {

    display: none;

	.apnic-header-brand {

		padding: 24px 0;

		font-size: ($font-size-base * 3);

		a {
			color: $gray-800;

			&:hover {
				text-decoration: none;
			}
		}

		#glyph.header-glyph {
			filter: grayscale(100%);
		}
	}

    @media only screen and (min-width: map-get($grid-breakpoints, lg)) {

        display: block;

        #glyph.header-glyph {
            display: block;
            position: absolute;
            top: -4px;
            right: 0;
            z-index: $z-below;
            background-image: url("../images/apnic-icons.png");
            width: 150px;
            height: 120px;
        }
    }

	.header-glyph-blue-1 {
		background-position: 0 0;
	}

	.header-glyph-blue-2 {
		background-position: -150px 0;
	}

	.header-glyph-blue-3 {
		background-position: -300px 0;
	}

	.header-glyph-blue-4 {
		background-position: -450px 0;
	}

	.header-glyph-blue-5 {
		background-position: -600px 0;
	}

	.header-glyph-darkgreen-1 {
		background-position: 0 -150px;
	}

	.header-glyph-darkgreen-2 {
		background-position: -150px -150px;
	}

	.header-glyph-darkgreen-3 {
		background-position: -300px -150px;
	}

	.header-glyph-darkgreen-4 {
		background-position: -450px -150px;
	}

	.header-glyph-darkgreen-5 {
		background-position: -600px -150px;
	}

	.header-glyph-lightgreen-1 {
		background-position: 0 -300px;
	}

	.header-glyph-lightgreen-2 {
		background-position: -150px -300px;
	}

	.header-glyph-lightgreen-3 {
		background-position: -300px -300px;
	}

	.header-glyph-lightgreen-4 {
		background-position: -450px -300px;
	}

	.header-glyph-lightgreen-5 {
		background-position: -600px -300px;
	}

	.header-glyph-orange-1 {
		background-position: 0 -450px;
	}

	.header-glyph-orange-2 {
		background-position: -150px -450px;
	}

	.header-glyph-orange-3 {
		background-position: -300px -450px;
	}

	.header-glyph-orange-4 {
		background-position: -450px -450px;
	}

	.header-glyph-orange-5 {
		background-position: -600px -450px;
	}

	.header-glyph-purple-1 {
		background-position: 0 -600px;
	}

	.header-glyph-purple-2 {
		background-position: -150px -600px;
	}

	.header-glyph-purple-3 {
		background-position: -300px -600px;
	}

	.header-glyph-purple-4 {
		background-position: -450px -600px;
	}

	.header-glyph-purple-5 {
		background-position: -600px -600px;
	}

	.header-glyph-yellow-1 {
		background-position: 0 -750px;
	}

	.header-glyph-yellow-2 {
		background-position: -150px -750px;
	}

	.header-glyph-yellow-3 {
		background-position: -300px -750px;
	}

	.header-glyph-yellow-4 {
		background-position: -450px -750px;
	}

	.header-glyph-yellow-5 {
		background-position: -600px -750px;
	}
}
